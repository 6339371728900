<template>

	<div style="padding: 20px;text-align: center;">
		<!-- <span id="LoginWithAmazon" @click="LoginWithAmazon">
			<img border="0" alt="Login with Amazon" src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png" width="156" height="32" />
		</span>
		<div id="amazon-root"></div> -->

		<div style="padding: 20px 10px; font-size: 16px;">

			<div class="step-con">
				<!-- <div class="step-title">STEP1</div> -->

				<div class="step-msg">Temu<span>授权</span></div>

				<!-- <div class="step-loading-icon" style=""><i class="el-icon-loading"></i></div> -->

				<div class="auth-icon-con">
					<div class="auth-icon-az">
						<img src="../../assets/images/auth/temu.png" width="150" height="auto" />
					</div>
					<div class="auth-icon-arrow">
						<img src="../../assets/images/amazon/arrow-bothway.png" width="30" height="auto" />
					</div>
					<div class="auth-icon-hytx">
						<img src="../../assets/logo_en.png" width="150" height="auto" />
					</div>
				</div>

				<!-- <a target="_blank" :href="authSrc">{{$t('i18nn_907aa9302190b8b6')}}</a> -->
			</div>

			<div style="margin-bottom: 20px;">
				<!-- <el-button type="primary" @click="toAuth()" icon="el-icon-right">授权</el-button> -->
			</div>

			<div style="padding: 20px;" v-loading="loading">
				<el-descriptions title="授权信息" :column="2" border>
					<el-descriptions-item label="app_key">
						{{queryData.app_key}}
					</el-descriptions-item>
					<el-descriptions-item label="locale">
						{{queryData.locale}}
					</el-descriptions-item>
					<el-descriptions-item label="shop_region">
						{{queryData.shop_region}}
					</el-descriptions-item>
					<el-descriptions-item label="tpCode">
						{{queryData.tpCode}}
					</el-descriptions-item>
					<el-descriptions-item label="code">
						{{queryData.code}}
					</el-descriptions-item>
				</el-descriptions>




				<!-- <div style="margin-bottom: 10px;"> -->

				<!-- <div style="color: #999; margin-bottom: 10px;">{{$t('i18nn_2ed49c526ba70768')}}</div> -->

				<!-- <el-button type="primary" plain @click="toAuthValidate()">第一步(验证)</el-button> -->

				<!-- <el-button type="primary" plain @click="testAuthBack()">第二步(回调获取CODE)</el-button> -->
				<!-- </div> -->

				<!-- <div style="margin-bottom: 10px;">
					ClientID：{{ClientID}}
				</div> -->
			</div>
			
			<!-- <div>
				<el-button type="primary" @click="toRefreshShop()" icon="el-icon-refresh-right">获取店铺</el-button>
			</div> -->
			
		</div>
	</div>


</template>

<script>
	export default {
		data() {
			return {
				loading: false,
				queryData: {}
				// ClientID: "lOOYRLaEgkDizg1G7rAmbNGMmRN2ixyaw3PuHhNboAGfq3Pp",
				// ClientID: "",
				// redirect_uri: "https://www.baidu.com",
				// redirect_uri: "https://warehouse.hytx.com/AuthFrame/UpsOauthBack",
			}
		},
		created() {

		},
		mounted() {
			this.queryData = this.$route.query;
			// this.getUpsAuthKey();
		},
		methods: {
			// toAuthValidate() {
			// 	let ClientID = this.ClientID;
			// 	let redirect_uri = encodeURIComponent(this.redirect_uri);
			// 	// window.location.href=`https://wwwcie.ups.com/security/v1/oauth/validate-client?client_id=${testClientID}&redirect_uri=${redirect_uri}`;
			// 	let baseUrl = "https://onlinetools.ups.com";
			// 	// let baseUrl = "https://wwwcie.ups.com/security";
			// 	let url =
			// 		`${baseUrl}?client_id=${ClientID}&redirect_uri=${redirect_uri}`;

			// 	// let url = `/upsCie/security/v1/oauth/validate-client?client_id=${ClientID}&redirect_uri=${redirect_uri}`;
			// 	// window.location.href = url;
			// 	window.open(url,"_blank"); 
			// },
			//授权
			toAuth() {
				this.loading = true;

				this.$http
					.put(this.$urlConfig.WhThirdAuthToken, {
						"tpCode": this.queryData.tpCode,
						"authCode": this.queryData.code
					})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_776496ec446f21f9'), data);
						// console.log(data);

						this.loading = false;
						if (200 == data.code) {
							
							this.$message.success('授权成功');
							//获取店铺
							this.toRefreshShop();
						} else {
							if (!data.msg) {
								data.msg = '授权失败';
							}
							this.$message.warning(data.msg);
						}
					})
					.catch(error => {
						// console.log(error);
						console.log(this.$t('tips.errorData'), error);
						this.loading = false;
						this.$alert('授权失败', this.$t('i18nn_cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});

			},
			
			//刷新店铺
			toRefreshShop(){
				this.loading = true;
				
				this.$http
					.put(this.$urlConfig.WhThirdShopByTpCode, {
						"tpCode": this.queryData.tpCode,
						// "authCode": this.queryData.code
					})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_776496ec446f21f9'), data);
						// console.log(data);
				
						this.loading = false;
						if (200 == data.code) {
							this.$message.success('刷新店铺成功');
							this.$router.push({name:"ThirdApplicationList"})
						} else {
							if (!data.msg) {
								data.msg = '刷新店铺失败';
							}
							this.$message.warning(data.msg);
						}
					})
					.catch(error => {
						// console.log(error);
						console.log(this.$t('tips.errorData'), error);
						this.loading = false;
						this.$alert('刷新店铺失败', this.$t('i18nn_cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},

			// testAuthBack() {
			// 	this.authBack({
			// 		type: "ups_com_api"
			// 	});
			// },
			// authBack(data) {
			// 	// return;
			// 	this.loading = true;
			// 	// https://www.ups.com/lasso/signin?client_id=testClientID&redirect_uri=https://testapplication.com&response_type=code&scope=read&type=ups_com_api
			// 	let client_id = this.ClientID;
			// 	let redirect_uri = encodeURIComponent(this.redirect_uri);
			// 	let response_type = "code";
			// 	let scope = "read";
			// 	let type = data.type;
			// 	let RedirecUrl = data.LassoRedirectURL ? data.LassoRedirectURL : 'https://www.ups.com/lasso/signin';
			// 	// window.location.href =
			// 	// 	`https://www.ups.com/lasso/signin?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}&scope=${scope}&type=${type}`;
			// 	window.location.href =
			// 		`${RedirecUrl}?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}&scope=${scope}&type=${type}`;
			// 	this.loading = false;
			// },
			// //获取Upskey
			// getUpsAuthKey() {
			// 	this.loading = true;

			// 	this.$http
			// 		.get(this.$urlConfig.upsAuthGetKey, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_776496ec446f21f9'), data);
			// 			// console.log(data);

			// 			this.loading = false;
			// 			if ('200' == data.code) {
			// 				this.ClientID = data.data.clientId;
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = '获取key失败';
			// 				}
			// 				this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			// console.log(error);
			// 			console.log(this.$t('tips.errorData'), error);
			// 			this.loading = false;
			// 			this.$alert('获取key失败！', this.$t('i18nn_cc62f4bf31d661e3'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },
		}
	}
</script>

<style lang="less" scoped>
	.step-con {
		line-height: 150%;
	}

	.step-title {
		font-size: 32px;
		padding: 10px 0;
	}

	.step-loading-icon {
		font-size: 24px;
		padding: 10px 0;
	}

	.step-msg {
		font-size: 18px;
		padding: 10px 0;
	}

	.auth-icon-con {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px;

		.auth-icon-az {
			padding: 0 20px;
		}

		.auth-icon-arrow {
			padding: 0 20px;
		}

		.auth-icon-hytx {
			padding: 0 20px;
		}
	}
</style>